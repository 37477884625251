body {
    min-height: 110vh;
    display: flex;
    flex-direction: column;
}

.pagination {
    background-color: #f8fafc!important;
}

nav {
    .navbar-toggler-icon {
        color: $house-orange !important;
    }

    position: relative !important;
    background-color: rgba(170, 170, 170, 0.4) !important;

    font-weight: bold !important;


    a {
        color: rgba(240, 76, 37, 0.8) !important;

    }

    a:hover {
        color: $house-orange !important;

    }
}

footer {
    margin-top: auto;
}

#clouds {
    transform: rotate(180deg) !important;
    fill: #343A40 !important;
}

.navColor {
    background-color: #343A40 !important;
}

.panel-icon {
    font-size: 2rem;
    color: rgb(87, 87, 87);
}

.border-extra-beheer {
    border-left: 2px solid rgba(87, 87, 87, 0.7);
}

.bg-house-orange {
    background-color: $house-orange;
}

.bg-house-blue {
    background-color: $house-blue;
}

.bg-house-gray {
    background-color: $house-gray;
}

.text-house-orange {
    color: $house-orange;
}

.text-house-blue {
    color: $house-blue;
}

.text-house-gray {
    color: $house-gray;
}


.help {
    a {
        color: rgba(240, 76, 37, 0.8) !important;

    }

    a:hover {
        color: $house-orange !important;

    }
}
